export default {
	init: (Swiper, Autoplay, Pagination) => {

		const heroSliders = document.querySelectorAll('.hero--slider');

		heroSliders.forEach(element => {

			const swiperContainer = element.querySelector('[data-swiper="hero"]')

			const swiper = new Swiper(swiperContainer, {

				modules: [Autoplay, Pagination],
				autoplay: {
					delay: 5000,
					disableOnInteraction: false,
				},
				// autoHeight: true,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				breakpoints: {
					1024: {
						autoHeight: false
					},
				},

			});

			swiper.on('slideChange', e => {

				const currentSlide = e.slides[e.realIndex];

				if (currentSlide.querySelector('.hero-image')) {

					const img = currentSlide.querySelector('.hero-image')

					if (img.dataset.mask == 'true') {
						document.querySelector('.hero--slider').style.setProperty('--hero-image', `url('${ img.getAttribute('src') }')`)
					} else {
						document.querySelector('.hero--slider').style.setProperty('--hero-image', '')
					}
					
				} else {
					document.querySelector('.hero--slider').style.setProperty('--hero-image', '')
				}

			})
			
		});

	}
}
