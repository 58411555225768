export default {
	init: (app, gsap) => {


		/*-------- Advantages --------*/
		const sectionAdvantages = document.querySelector('section.advantages')

		if (sectionAdvantages) {

			const imgs = sectionAdvantages.querySelectorAll('.advantages-gallery-image')

			const tlAdvantages = gsap.timeline({

				paused: true,
				scrollTrigger: {

					trigger: sectionAdvantages,
					start: "50% 80%",
					end: "50% 80%",
					once: true,
					onEnter: (self) => {

						self.trigger.dataset.decorations = 'true'

						tlAdvantages.play()

					},

				}

			});

			imgs.forEach(element => {

				tlAdvantages.to(element, {
					opacity: 1,
					x: 0,
					duration: 1
				}, '<')

			});

		}

		/*-------- Products (Taxonomy listing) --------*/
		// const sectionProducts = document.querySelector('section.products')

		// if (sectionProducts) {

		// 	const productsListing = sectionProducts.querySelector('.products-listing')

		// 	const productsItem = gsap.utils.toArray('.products-item')

		// 	const tlProducts = gsap.timeline({

		// 		paused: true,
		// 		scrollTrigger: {

		// 			trigger: sectionProducts,
		// 			pin: true,
		// 			start: `top+=${productsListing.offsetTop} top`,
		// 			end: `+=${sectionProducts.offsetHeight - productsListing.offsetTop}`,
		// 			scrub: 1,

		// 		}

		// 	});

		// 	productsItem.forEach(element => {

		// 		tlProducts.to(element, {
		// 			attr: { ["data-active"]: 'true' },
		// 			duration: 2
		// 		})

		// 		tlProducts.to({}, {
		// 			duration: 1
		// 		})

		// 		tlProducts.to(element, {
		// 			attr: { ["data-active"]: 'false' },
		// 			duration: 2
		// 		})

		// 	});

		// }

		/*-------- Img + Text (Taxonomy listing) --------*/
		const sectionImgTxt = document.querySelector('section.img_txt')

		if (sectionImgTxt) {

			const img = sectionImgTxt.querySelector('.img_txt-img_ctr img')
			const imgCtr = sectionImgTxt.querySelector('.img_txt-img_ctr')
			const txt = sectionImgTxt.querySelector('.img_txt-content')

			const tlImgTxt = gsap.timeline({

				paused: true,
				scrollTrigger: {

					trigger: sectionImgTxt,
					start: "top 50%",
					end: "top 50%",
					toggleActions: 'play none none none',
					once: true,
					onEnter: () => {
						tlImgTxt.play();
					}

				}

			});

			tlImgTxt.fromTo(img, {
				autoAlpha: 0,
				y: 50,
			}, {
				autoAlpha: 1,
				y: 0,
				duration: 1
			})

			tlImgTxt.to(imgCtr, {
				attr: { ["data-decorations"]: 'true' },
			}, '<')

			tlImgTxt.fromTo(txt, {
				autoAlpha: 0,
				y: 50,
			}, {
				autoAlpha: 1,
				y: 0,
				duration: 1
			})

		}

		/*-------- Benefits --------*/
		let mm = gsap.matchMedia();
		const benefitsContainer = document.querySelector('.benefits');

		mm.add("(min-width: 80rem)", () => {
			if (benefitsContainer) {

				const benefitsItems = document.querySelectorAll('.benefits-gallery-item');
				const benefitsItemsWidth = benefitsItems[0].offsetWidth;


				function mergeCircles() {
					gsap.to(benefitsItems, {
						x: 0,
						y: 0,
						stagger: { each: 0.1, from: 'end' },
						duration: 0.5
					});
				}

				function spreadCircles() {
					gsap.to(benefitsItems, {
						x: (i) => (i - (benefitsItems.length - 1) / 2) * (benefitsItemsWidth + 20),
						duration: 0.5
					});
				}


				const observer = new IntersectionObserver(entries => {
					entries.forEach(entry => {
						if (entry.isIntersecting) {
							// console.log('intersecting')
							spreadCircles();
						}
						else {
							// console.log('not intersecting')
							mergeCircles();
						}
					});
				}, {
					threshold: .5
				});

				observer.observe(benefitsContainer);
			}
		});
	}
}