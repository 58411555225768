/*
|
| Importing Libs
|------------------
*/
import Swiper, { Autoplay, Pagination, EffectCoverflow } from 'swiper';
import gsap from 'gsap';
import SplitText from '@lib/gsap-pro/SplitText';
import ScrollTo from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger, ScrollTo);

/*
|
| Importing Components
|-----------------------
*/
import customGoogleMap from '@components/custom-google-map';
import MaterializeForm from '@components/materialize-form';
import Kira from '@components/kira';
import Menu from '@components/menu';
import Overlay from '@components/overlays';

/*
|
| Importing Utils
|-------------------
*/
import * as app from '@utils/global';
import Router from '@utils/router';
import Ajax from '@utils/ajax';

/*
|
| Importing App files
|----------------------
*/
import main from '@pages/main';
// import news from '@pages/news';
import animations from '@pages/animations';
import animationsCustom from '@pages/animationsCustom';
import products from '@pages/products';
import contact from '@pages/contact';

import heroSlider from '@pages/hero-slider';

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        file: animations,
        dependencies: [app, gsap, SplitText, Menu, Kira]
    },
    {
        file: animationsCustom,
        dependencies: [app, gsap]
    },
    {
        file: main,
        dependencies: [app, Overlay, gsap, ScrollTrigger, Swiper, Autoplay, EffectCoverflow],
    },
    // {
    //     file: news,
    //     dependencies: [app, Ajax],
    //     resolve: '.news-container'
    // },
    {
        file: products,
        dependencies: [app],
        resolve: '#page-products-archive'
    },

    // {
    //     file: contact,
    //     dependencies: [app, MaterializeForm, customGoogleMap],
    //     resolve: '#page-contact'
    // },
    {
        file: heroSlider,
        dependencies: [Swiper, Autoplay, Pagination],
        resolve: '.hero--slider'
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);
