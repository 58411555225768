export default {
	init: (app, Overlay, gsap, ScrollTrigger, Swiper, Autoplay, EffectCoverflow) => {
		/*-------- Lazy loading --------*/
		document.querySelectorAll('[data-src], [data-bg]').forEach((item) => app.lazyLoader.observe(item))

		/*-------- Modals --------*/
		document.querySelectorAll('.modal').forEach((item) => {
			app.overlays[item.id] = new Overlay(item)
		})
		
		/*-------- CSS Vars --------*/
		const root = document.documentElement

		if ( document.getElementById('header') ) {
			
			root.style.setProperty('--header-height', `${document.getElementById('header').offsetHeight}px`)
	
			window.addEventListener('resize', e => {
	
				root.style.setProperty('--header-height', `${document.getElementById('header').offsetHeight}px`)
	
			});

		}

		document.querySelectorAll('[data-shape-observer]').forEach((element) => app.shapeObserver.observe(element))
		document.querySelectorAll('[data-character-observer]').forEach((element) => app.characterObserver.observe(element))

		/*-------- Products (Homepage) --------*/
		const products = document.querySelector('.products')

		if (products) {

			const productsItemTitle = products.querySelectorAll('.products-item-title')

			productsItemTitle.forEach(element => {

				element.addEventListener('mouseover', e => {

					if ( e.currentTarget.closest('.products-item').dataset.active == 'false' ) {

						productsItemTitle.forEach(element => {

							element.closest('.products-item').dataset.active = 'false'

						})

						e.currentTarget.closest('.products-item').dataset.active = 'true'
						
					}

				})

				element.addEventListener('touchstart', e => {

					if ( e.currentTarget.closest('.products-item').dataset.active == 'false' ) {

						productsItemTitle.forEach(element => {

							element.closest('.products-item').dataset.active = 'false'

						})

						e.currentTarget.closest('.products-item').dataset.active = 'true'
						
					}

				})

			})
			
		}

		/*-------- Slider Follow (w/ Swiper) --------*/
		const sliderFollow = document.querySelector('[data-swiper="follow"]')

		if (sliderFollow) {

			const swiper = new Swiper(sliderFollow, {
				modules: [Autoplay, EffectCoverflow],
				autoplay: {
					delay: 2500,
					disableOnInteraction: false,
				},
				effect: "coverflow",
				loop: true,
				slidesPerView: 3,
				spaceBetween: 8,
				centeredSlides: true,
				coverflowEffect: {
					depth: 100,
					modifier: 1,
					rotate: 0,
					scale: 1,
					slideShadows: false,
					stretch: -8,
				},
				breakpoints: {
					576: {
						spaceBetween: 25,
						coverflowEffect: {
							modifier: 2,
							stretch: -25,
						},
					},
				},
			});
			
		}

	}
}