export default {
	init: (app) => {

		const root = document.documentElement
		const sectionAnchors = document.querySelector('.section-anchors')
		
		if (sectionAnchors) {

			const sectionPosts = document.querySelector('.section-posts')

			// Set Scroll Padding-Top as CSS Var			
			root.style.setProperty('--scroll-pt', `${ (sectionAnchors.offsetHeight + 16) / 16}rem`)

			// Observe terms container reaching top
			const observerTermCtr = new IntersectionObserver(entries => {

				entries.forEach(entry => {
	
					if ( entry.isIntersecting ) {
						root.style.setProperty('--scroll-pt', `${ (sectionAnchors.offsetHeight + 16) / 16}rem`)
						sectionAnchors.dataset.active = 'true'
					} else {
						root.style.setProperty('--scroll-pt', `${ ((sectionAnchors.offsetHeight * 2) + 16) / 16}rem`)
						sectionAnchors.dataset.active = 'false'
					}
	
				})
	
			}, {
				rootMargin: `${sectionAnchors.offsetHeight + 16 + 1}px 0px -${window.innerHeight - sectionAnchors.offsetHeight - 16 - 1}px 0px`
			})
	
			observerTermCtr.observe( sectionPosts )

			// Observe current term (Anchor)
			const observerTerm = new IntersectionObserver(entries => {
	
				entries.forEach(entry => {
	
					let entryId = entry.target.id;
					let entryAnchor = document.querySelector(`.section-anchors-link[href="#${entryId}"]`)
	
					if ( entry.isIntersecting ) {
						entryAnchor.dataset.active = 'true'
					} else {
						entryAnchor.dataset.active = 'false'
					}
	
				})
	
			}, {
				rootMargin: `-${sectionAnchors.offsetHeight + 16 + 1}px 0px -${window.innerHeight - sectionAnchors.offsetHeight - 16 - 1}px 0px`
			})
	
			document.querySelectorAll('.section-posts-term').forEach( element => observerTerm.observe( element ) )

		}

	}
}